import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSavingsAccountList,
  setCheckingAccountList,
} from "../../redux/sliceAccounts";
import { updateInfo, reset } from "../../redux/sliceAuth";
import { RootState } from "../../redux/store";
import {
  useLazyDisconnectAccountQuery,
  useLazyGetUserAccountsQuery,
} from "../../api/apiPlaid";
import { useLazyDisconnectUserQuery } from "../../api/apiAuth";
import { getAuth, signOut } from "firebase/auth";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";
import EditablePersonalInfo from "./EditablePersonalInfo";
import Loader from "../../elements/Loader/Loader";
import DeleteAccountModal from "../../elements/Modals/DeleteAccountModal";
import DeleteQuestionsModal from "../../elements/Modals/DeleteQuestionsModal";
import trash_icon from "../../assets/trash_icon.svg";
import "./Settings.css";
import "rc-dropdown/assets/index.css";
import "react-toggle/style.css";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import NotificationBar from "../../elements/NotificationBar/NotificationBar";
import {
  resetDisabledAutomation,
  resetUserAutomation,
} from "../../redux/sliceUserAutomation";
import { setReconnectError } from "../../redux/sliceNotifications";
import { AuthContext } from "../../context/AuthContext";
// @ts-ignore
import BackButton from "../../elements/BackButton/BackButton";
import { useMixpanel, useMixpanelPublic } from "../../helpers/mixpanel";
import ConnectedAccounts from "./ConnectedAccounts";

function Settings() {
  const context = useContext(AuthContext);
  const [getAccountsList, accountsList] = useLazyGetUserAccountsQuery();
  const [disconnectUser, disconnectUserResult] = useLazyDisconnectUserQuery();
  const [disconnectAccount, disconnectResult] = useLazyDisconnectAccountQuery();
  const [showDeleteModalAccount, setShowDeleteModalAccount] = useState(false);
  const [showWarningnDeleteAccount, setShowWarningnDeleteAccount] =
    useState(false);
  const [showDiscconectUserModal, setShowDiscconectUserModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [editableMode, setEditableMode] = useState(false);
  const mixpanel = useMixpanel();
  const mixpanelPublic = useMixpanelPublic();
  const disabledAutomation: any = useSelector<RootState>(
    (state) => state?.userAutomation?.disabledAutomation,
  );

  const userData: any = useSelector<RootState>(
    (state) => state?.userNotifications?.userData,
  );
  useEffect(() => {
    const { user } = context;
    if (!user) {
      dispatch(reset());
      dispatch(resetUserAutomation());
      navigation("/login");
    } else {
      user.getIdToken().then(async (idToken: string) => {
        await getAccountsList({ idToken });
      });
    }
  }, [disabledAutomation]);

  useEffect(() => {
    if (accountsList.data) {
      const checkingAccount: any = accountsList.data.data.filter(
        (i: any) => i.subtype === "checking",
      );
      const savingsAccount: any = accountsList.data.data.filter(
        (i: any) => i.subtype === "savings",
      );
      dispatch(setCheckingAccountList(checkingAccount));
      dispatch(setSavingsAccountList(savingsAccount));
    } else {
      return;
    }
  }, [accountsList.data]);

  useEffect(() => {
    if (userData) {
      const {
        address,
        street,
        apartment,
        birthday,
        city,
        email,
        firstName,
        lastName,
        ssn,
        state,
        zipCode,
      } = userData;

      const ssnNumbers = ssn ? ssn.split("").map(Number) : "";
      const birthdayDate = birthday ? new Date(birthday) : "";

      dispatch(
        updateInfo({
          email,
          address,
          street,
          apartment,
          birthday,
          birthdayDay: birthdayDate ? birthdayDate.getDate() : "",
          birthdayMonth: birthdayDate ? birthdayDate.getMonth() + 1 : "",
          birthdayYear: birthdayDate ? birthdayDate.getFullYear() : "",
          city,
          firstName,
          lastName,
          ssnSix: ssnNumbers.length === 9 ? ssnNumbers[5] : ssnNumbers[0],
          ssnSeven: ssnNumbers.length === 9 ? ssnNumbers[6] : ssnNumbers[1],
          ssnEight: ssnNumbers.length === 9 ? ssnNumbers[7] : ssnNumbers[2],
          ssnNine: ssnNumbers.length === 9 ? ssnNumbers[8] : ssnNumbers[3],
          state,
          zipCode,
        }),
      );
    }
  }, [userData]);

  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );

  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );
  useEffect(() => {
    if (!checkingAccounts) {
      localStorage.removeItem("selectCheckingAcc");
    }
    if (!savingsAccounts) {
      localStorage.removeItem("selectSavingsAcc");
    }
  }, []);

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const disconnectAccountHandler = (account: any) => {
    setSelectedAccountType(account.subtype);
    setSelectedAccount(account.id);
    if (account?.isUsedInAutomation === true) {
      setShowWarningnDeleteAccount(true);
    } else {
      setShowDeleteModalAccount(true);
    }
  };

  const deleteAccount = (answer: any, userMessage?: any) => {
    const { user } = context;
    if (!user) {
      dispatch(reset());
      dispatch(resetUserAutomation());
      navigation("/login");
    } else {
      user.getIdToken().then(async (idToken: string) => {
        const id = selectedAccount;
        mixpanel("User_actively_disconnected_bank", {}, idToken);
        await disconnectAccount({ idToken, id }).then(() =>
          notification({
            message: "Account disconnected.",
            type: "disconnect",
          }),
        );
        await getAccountsList({ idToken });

        setSelectedAccount(null);
        if (selectedAccountType === "checking") {
          localStorage.removeItem("selectCheckingAcc");
        }
        if (selectedAccountType === "savings") {
          localStorage.removeItem("selectSavingsAcc");
        }
      });
    }
  };

  const reloadPage = () => {
    notification({
      message: "Reconnection successful. Automation resumed.",
      type: "success",
    });
    dispatch(resetDisabledAutomation());
    dispatch(setReconnectError(false));
    navigation("/automations");
  };

  const disconnectUserAccount = () => {
    mixpanel("Start_account_delete_flow");
    setShowDiscconectUserModal(true);
  };

  const discconectUserAccount = async (
    answerList: any,
    userAnswer: any = [],
  ) => {
    const { user } = context;
    if (!user) {
      dispatch(reset());
      dispatch(resetUserAutomation());
      navigation("/login");
    } else {
      await user.getIdToken().then(async (idToken: string) => {
        mixpanel(
          "Account_delete_reasons_selected",
          {
            "Reasons selected": answerList,
          },
          idToken,
        );
        if (userAnswer.length) {
          mixpanel(
            "Account_delete_feedback_entered",
            {
              "Feedback text": userAnswer,
            },
            idToken,
          );
        }
        await disconnectUser({ idToken, answerList, message: userAnswer });
        // added field for user textArea
      });
      await user.delete().then(() => {
        mixpanel("Account_deleted");
        navigation("/");
      });
    }
  };

  useEffect(() => {
    if (disconnectUserResult.isSuccess) {
      dispatch(reset());
      dispatch(resetUserAutomation());
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          navigation("/login");
        })
        .catch((error) => {
          mixpanelPublic("Error", { message: error });
          console.log(error);
        });
    }
  }, [disconnectUserResult]);

  return (
    <div>
      <HeaderMenu />
      <NotificationBar />
      <div className="settings__container">
        <div className="profile">
          <div className="backButton__holder">
            {editableMode && (
              <BackButton
                onBack={() => {
                  setEditableMode(false);
                }}
              />
            )}
          </div>
          <div id="personal-info">
            <EditablePersonalInfo
              editableMode={editableMode}
              setEditableMode={setEditableMode}
            />
          </div>
          <ConnectedAccounts
            checkingAccounts={checkingAccounts}
            savingsAccounts={savingsAccounts}
            reloadPage={reloadPage}
            disconnectAccountHandler={disconnectAccountHandler}
          />
          <div
            className="profile__blockInfo-delete"
            onClick={disconnectUserAccount}
          >
            <div className="account__infoRow deleteBlock">
              <div className="deleteAccount text__content" id="delete-account">
                Delete Cache account
              </div>
              <div>
                <img src={trash_icon} alt="Trash Icon" />
              </div>
            </div>
          </div>
        </div>
        {showDeleteModalAccount && (
          <DeleteAccountModal
            onHide={() => setShowDeleteModalAccount(false)}
            deleteAction={deleteAccount}
            title="Disconnect this account?"
            description="If you disconnect this account, it cannot be used in a future automation. You’ll be able to reconnect it at any time."
            type={"disconnect"}
          />
        )}
        {showWarningnDeleteAccount && (
          <DeleteAccountModal
            onHide={() => {
              setShowWarningnDeleteAccount(false);
              navigation("/automations");
            }}
            title="This account cannot be disconnected."
            deleteAction={() => navigation("/automations")}
            description="This account is part of an active automation. In order to disconnect this account, you must first delete the automation."
            type={"disableDisconnect"}
          />
        )}

        {showDiscconectUserModal && (
          <DeleteQuestionsModal
            onHide={() => {
              setShowDiscconectUserModal(false);
            }}
            deleteAction={discconectUserAccount}
            type={"account"}
          />
        )}
      </div>
      <Loader
        loading={
          accountsList.isFetching ||
          disconnectUserResult.isFetching ||
          disconnectResult.isFetching
        }
      />
    </div>
  );
}

export default Settings;
